import React,{useEffect,useState} from 'react';
import './App.css';
import { Toast } from '@nutui/nutui-react';
import '@nutui/nutui-react/dist/style.css'
import axios from 'axios'

function JudicialApp(props) {
  const [data, setData] = useState("");
  const [isShow,setIsShow]=useState(false)
  const duringToast = (msg) => {
    Toast.loading(msg, { duration: 2, cover: true, })
  }
  useEffect(() => {
   // duringToast("数据核验中...")
    const arr = props.url.split('?');
    const host ="https://testapi.gzbqdj.com/";
    axios.get(host+'web/judicial/qrCode?judicial='+encodeURIComponent(arr[1].substring(9)), {
      params: {}
    }).then(res => {
      if (res.status == 200) {
        if (res.data.rcode == 200) {
          if (res.data.result) {
             setData(res.data.result)
              setIsShow(false)
          } else {
              setIsShow(true)
          }
         
        }
      }    
    }).catch(err => {
    
    })
  }, [])
  
  //日期格式化
	 const formDate=(timestamp)=> {
		var date = new Date(timestamp);
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = date.getDate() + ' ';
        var h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
        var m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
		return Y+M+D +h+m+s
  }
  return (
    <div className="App">
      {data && !isShow ?
        <div className="listWrap">
          <div className='title'>核验结果</div>
          <img src={require("./img/success.png")} style={{ width: 380 }} />
          <div className='content'>
            <div className='hash'>
              <div style={{ color: "#06c", margin: "5px 0" }}>统一证据编号：</div>
              <div>{data.txHash}</div>
            </div>
            <div className='hash'>
              <div style={{ color: "#06c", margin: "5px 0" }}>所在区块hash：</div>
              <div>{data.hash}</div>
            </div>
            <div className='hash'>
              <div style={{ color: "#06c", margin: "5px 0" }}>存证时间：</div>
              <div>{data.blockTimestamp ? formDate(data.blockTimestamp) : "--"}</div>
            </div>
            <div className='hash'>
              <div style={{ color: "#06c", margin: "5px 0" }}>存证类型：</div>
              <div>{data.blockType === 1 ? "文件存证" : "哈希存证"}</div>
            </div>
            <div className='hash'>
              <div style={{ color: "#06c", margin: "5px 0" }}>块高：</div>
              <div>{data.blockHeight}</div>
            </div>
          </div>
        </div> : null}
        {isShow&&!data?<div className='listWrap' >
         <div className='title'>核验结果</div>
          <img src={require("./img/err.png")} style={{ width: 240,margin:"0 auto" }}  />
        </div>:null}
    </div>
  );
}

export default JudicialApp;
