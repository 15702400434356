import React,{useEffect,useState} from 'react';
import './App.css';
import JudicialApp from './judicial';
import RegisterApp from './register';
function App() {
  const [url, setUrl] = useState("");
  const [isJudicial,setIsJudicial]=useState(false)
  useEffect(() => {
    let url = window.location.href;
   // let url = "https://query.gzbqdj.com/judicial?judicial=9npWpZL4l7UV3fB0p1OYHu2OvNj2Kn+SUJcXycNWZiY=";
    setUrl(url);
    let routerName = url.indexOf("/judicial");
    if (routerName > 0) {
      setIsJudicial(true)
    } else {
      setIsJudicial(false)
    }

  },[url])
  return (
    <div className="App">
      {isJudicial&&url? <JudicialApp url={url} /> : null}
      {!isJudicial&&url?<RegisterApp url={url}/>:null}
    </div>
  );
}

export default App;
