import React from "react";
import "../../../App.css";
import CellLabel from "../../CellLabel"
export default function SeizureList(props) {
    const { list } = props;
    const labelWidth = 80
    return (<div className="s-card">
        {
            list.map(item => <div className="s-card-warp">
                <CellLabel labelWidth={labelWidth} title="查封法院" value={item.seizureCourt} />
                <CellLabel labelWidth={labelWidth} title="文书编号" value={item.seizureFileNumber} />
                <CellLabel labelWidth={labelWidth} title="查封期限" style={{border:0}} value={`自${item.seizureTime}起至${item.unsealingTime}止`} />
                
                
            </div>)
        }
    </div>)
}