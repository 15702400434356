import { ImagePreview } from '@nutui/nutui-react'
import React, { useState } from "react";

export default function ImgPreview(props) {
   const {images} = props
   const [visible,setVisible] = useState(false)
    return (
        <>
        {images.length && images.map(i=><img onClick={()=>setVisible(true)} src={i.src} style={{width:'100%'}}></img>)}
        <ImagePreview images={images} visible={visible} onClose={()=>setVisible(false)} />
        </>
        
    )
}