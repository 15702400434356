import React from "react";
import { blockLabelMap, LabelMap } from "../contant"
import CellLabel from "../../CellLabel"
export default function InfoLabel(props) {
    const { isBlock, listData, style } = props;
    const lMap = isBlock ? blockLabelMap : LabelMap

    return (<div className='content' style={style}>
        {Object.keys(lMap).map(k => {
            const { label, filter = ''} = lMap[k]
            const value = listData[k] || ''
            const val = filter && value ? filter(value) : value
            return <CellLabel key={k} title={label} value={val} />
        })}
    </div>)
}