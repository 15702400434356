import React, { useEffect, useState } from "react";
import "../../App.css";
import "@nutui/nutui-react/dist/style.css";
import { paramObj } from '../../utils'
import InfoLabel from "./children/InfoLabel";
import { LOG_URL } from '../../utils/contant'
import { fitterImages } from "./contant"
import { Overlay } from '@nutui/nutui-react';
import ImgPreview from "./children/ImgPreview";
import Seizure from "./children/Seizure"
import "../../tac/css/tac.css"
import { HOST } from "../../utils/contant";
function RegisterApp(props) {
    const url = props?.url;
   // const url = 'https://testapi.gzbqdj.com/?blockCertNumber=黔作登字-2023-G-00287266'
    const registernumber = paramObj(url)['registerCertNumber'] || paramObj(url)['blockCertNumber']
    const QIAN = registernumber.search("黔作登字") !== -1
    const [listData, setListData] = useState("");
    const [isBlock, setIsBlock] = useState(!QIAN);
    const [imgs, setImgs] = useState([])
    // const Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"]
    // const agent = window.navigator.userAgent
  //  const wxAgent = Agents.some(i=>agent.indexOf(i)>-1) && agent.toLowerCase().indexOf('micromessenger') > -1
    const [visible, setVisible] = useState(true)
    useEffect(() => {
        if (registernumber) {
            const config = {
                requestCaptchaDataUrl: `${HOST}web/captcha/gen`,
                validCaptchaUrl: `${HOST}web/register/small/certDirect?registernumber=${registernumber}`,
                bindEl: "#captcha-div",
                // 验证成功回调函数
                validSuccess: (res, c, tac) => {
                    setVisible(false)
                    setListData(res?.data?.id || {});
                    const registerCertificateUrl = res?.data?.id?.registerCertificateUrl
                    if (registerCertificateUrl) {
                        setImgs(fitterImages(registerCertificateUrl.split(',')))
                    }
                    tac.destroyWindow();
                }
            }
            let style = {
                logoUrl: null, moveTrackMaskBgColor: "#1991fa",
                moveTrackMaskBorderColor: "#333"
            }
            new window.TAC(config, style).init();

        }
    }, [registernumber]);

    return (
        <div className="App" style={{ paddingBottom: '20px' }}>
            <Overlay
                visible={visible} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <div style={{ position: 'relative' }}><div id="captcha-div"></div>
                            <div style={{ position: 'absolute', bottom: '0', left: '10px', width: '200px', height: '49px', background: '#fff', zIndex: 99999 }}></div>
                        </div>
                    </div>

                </Overlay>
                {!visible && <>
                    <img src={LOG_URL} style={{ width: 380 }} />
                    <InfoLabel
                        style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        isBlock={isBlock}
                        listData={listData}
                    />
                    {listData.registerBuildStatus ===3 && <Seizure list={listData.seizureList} />}
                    {listData.registerBuildStatus !==3 && imgs.length ? <div style={{marginTop:'20px'}}><ImgPreview images={imgs} /></div>:null}
                </>}
            {/* {!wxAgent ? <div style={{paddingTop:'40vh',textAlign:'center',fontSize:'20px',}}>
                请用手机在微信浏览器中打开
            </div> : (<>
                <Overlay
                    visible={visible}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <div style={{ position: 'relative' }}><div id="captcha-div"></div>
                            <div style={{ position: 'absolute', bottom: '0', left: '10px', width: '200px', height: '49px', background: '#fff', zIndex: 99999 }}></div>
                        </div>
                    </div>

                </Overlay>
                {!visible && <>
                    <img src={LOG_URL} style={{ width: 380 }} />
                    <InfoLabel
                        style={{ paddingLeft: '12px', paddingRight: '12px' }}
                        isBlock={isBlock}
                        listData={listData}
                    />
                    {listData.registerBuildStatus ===3 && <Seizure list={listData.seizureList} />}
                    {listData.registerBuildStatus !==3 && imgs.length ? <div style={{marginTop:'20px'}}><ImgPreview images={imgs} /></div>:null}
                </>}
            </>)} */}

         
        </div>
    );
}

export default RegisterApp;
