
const productName = {
    label: '作品名称',
}
const productCreateNatName = {
    label: '创作性质',
}
const productCatName = {
    label: '作品分类',
}
const submitterName = {
    label: '存证者',
}
const blockCertNumber = {
    label: '登记号',
}
const blockEvidenceTime = {
    label: '存证时间',
}
const productDescribe = {
    label: '作品描述',
}
const registerCertNumber = {
    label: '登记号',
}
const registerBuildStatus = {
    label: '证书状态',
    filter: v =>{
        if(v===2){
            return '<span style="color:#f33333">已撤销</span>'
        }else if(v===3){
            return '<span style="color:#f33333">已查封</span>'
        }else{
            return '<span>正常</span>'
        }
    } 
}
const productCat = {
    label: '作品类别',
}
const authorsNames = {
    label: '作者',
}
const copyrightsNames = {
    label: '著作权人',
}
const productPubStateName = {
    label: '发表状态',
}
const productPubAddr = {
    label: '发表地点',
}
const productCreateTime = {
    label: '创作完成日期',
}
const productPubTime = {
    label: '首次发表日期',
}
const auditTime = {
    label: '登记日期',
    filter: v => v ? v.substr(0, 10) : ''
}

export const blockLabelMap = {
    productName, 
    productCreateNatName, 
    productCatName, 
    submitterName, 
    blockCertNumber, 
    blockEvidenceTime, 
    productDescribe
}
export const LabelMap = {
    productName, 
    registerCertNumber, 
    copyrightsNames,
    productCatName: productCat, 
    productCreateTime,
    //authorsNames,
    productPubStateName,
  //  productPubAddr,
    productPubTime,
    auditTime,
    registerBuildStatus,
}
export const fitterImages = (arr)=>{
    return arr.reduce((t,v)=>{t.push({src:v});return t},[])
}